<template>
  <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
  <div class="container " >
    <div class="row" style="margin-top: 50px;">
        <div class="row">
            <div class="col-md-4">
          <div class="card-custom">
              <div class="row heading">
                <div class="col-sm-3 col-xs-3">
                  <img :src="getAvatar" class="avatar-img" width="40" height="40" style="float:left">
<!--                    {{ user.name }}-->
                </div>
                <div class="col-sm-5 col-xs-5"></div>
                <div class="col-sm-2 col-xs-2 heading-compose">
                  <a @click="openModalChat" title="Create new Chat"><i class="fas fa-user-plus fa-lg" aria-hidden="true"></i></a>
                </div>
                <teleport to="#modal-area">
                  <div v-if="modalOpenedChat" class="modal-area-chats">
                    <span @click="closeModalChat" class="close-modal-chats">&times;</span>
                    <div class="modal-header"><h3>New Chat:</h3></div>
                    <div class="modal-body">
                      <div class="row text-center">
                        <div class="form-group field-ticketform-estimated_time col-md-6">
                          <label class="control-label">Name</label>
                          <input type="text" class="form-control" v-model="name">
                        </div>
                        <div class="form-group field-ticketform-estimated_time col-md-6">
                          <label class="control-label">Description</label>
                          <input type="text" class="form-control" v-model="descryption">
                        </div>
                        <div class="form-group field-ticketform-assigned_to">
                          <label class="control-label" data-custom-for="ticketform-team_id"></label>
                          <Multiselect
                              v-model='userList'
                              mode='tags'
                              placeholder='Choose participants(s)...'
                              trackBy='name'
                              valueProp='id'
                              label='name'
                              :searchable='true'
                              :options='workers'
                          >
                            <template v-slot:tag="{ option, remove, disabled }">
                              <div class="multiselect-tag is-user">
                                <img class="user-avatar" :src="option.avatarLink">
                                {{ option.name }}
                                <i
                                    v-if="!disabled"
                                    @click.prevent
                                    @mousedown.prevent.stop="remove(option)"
                                />
                              </div>
                            </template>
                            <template v-slot:option="{ option }">
                              <img class="user-avatar" :src="option.avatarLink"> {{ option.name }}
                            </template>
                          </Multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <input type="submit" class="btn btn-warning" value="&#10011; Create">
                    </div>
                  </div>
                </teleport>

                <div class="col-sm-2 col-xs-2 heading-dot">
                  <a><i class="fas fa-ellipsis-h fa-lg" aria-hidden="true"></i></a>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1" style="margin: 10px 20px">
                  <input type="text" class="form-control" placeholder="Search..." v-model="searchChat">
                </div>
              </div>

<!--        &lt;!&ndash; =============================================================== &ndash;&gt;-->
<!--        &lt;!&ndash; member list &ndash;&gt;-->
              <div class="chats" v-if="chats.length>0">
                <ul class="friend-list" v-for="(chat, index) in chats"  :key="chat.id" :index="index">

                  <li class="bounceInDown" v-if="chat.participants.length>0">
                    <div v-if="chat.bookingId !== null && chat.bookingId !== undefined">
                      <a href="#" @click="setActiveChat(index)" class="clearfix">
                        <div class="" v-for="(participant) in chat.participants"  :key="participant.id" style="margin-left: 25px; ">
                          <img :src="participant.avatarImageLink"  :title="participant.name" alt="" class="avatar-group" style="margin-left: -30px;">
                        </div>
                          <div class="friend-name"  >
                            <strong>Booking ID {{ (chat.rentId)? chat.rentId : chat.bookingId }}</strong>
                          </div>
                        <div class="last-message text-muted">{{ (chat.messages.length >0 )? (chat.messages[chat.messages.length-1].author + ": " +
                            (isText(chat.messages[chat.messages.length-1])?chat.messages[chat.messages.length-1].message: (isVoice(chat.messages[chat.messages.length-1])?"Voice Message":"Photo Message"))): ""}}</div>
                        <small class="time text-muted">{{ (chat.messages.length >0 )? getTimeMask(chat.messages[chat.messages.length-1]): ""}}</small>
                        <small v-if="chat.unreadMessageCount>0" class="chat-alert custom-label rounded-pill">{{(chat.unreadMessageCount>0)?chat.unreadMessageCount>0 : ""}}</small>
                        <small v-else class="chat-alert"><i class="fas fa-check-double" style="color: #93918f"></i></small>
                      </a>
                    </div>
                    <div v-else-if="chat.ticketId !== null && chat.ticketId !== undefined">
                      <a href="#" @click="setActiveChat(index)" class="clearfix">
                        <div class="" v-for="(participant) in chat.participants"  :key="participant.id" style="margin-left: 25px; ">
                          <img :src="participant.avatarImageLink"  :title="participant.name" alt="" class="avatar-group" style="margin-left: -30px;">
                        </div>
                          <div class="friend-name">
                            <strong>Ticket # {{chat.ticketNumber}}</strong>
                          </div>
                        <div class="last-message text-muted">{{ (chat.messages.length >0 )? (chat.messages[chat.messages.length-1].author + ": " +
                        (isText(chat.messages[chat.messages.length-1])?chat.messages[chat.messages.length-1].message: (isVoice(chat.messages[chat.messages.length-1])?"Voice Message":"Photo Message"))): ""}}</div>
                        <small class="time text-muted">{{ (chat.messages.length >0 )? getTimeMask(chat.messages[chat.messages.length-1]): ""}}</small>
                        <small v-if="chat.unreadMessageCount>0" class="chat-alert custom-label rounded-pill">{{(chat.unreadMessageCount>0)?chat.unreadMessageCount>0 : ""}}</small>
                        <small v-else class="chat-alert"><i class="fas fa-check-double" style="color: #93918f"></i></small>
                      </a>
                    </div>
                    <div v-else >
                    <a href="#" @click="setActiveChat(index)" class="clearfix">
                      <div class="" v-for="(participant) in chat.participants"  :key="participant.id" style="margin-left: 25px; ">
                        <img :src="participant.avatarImageLink" :title="participant.name" alt="" class="avatar-group" style="margin-left: -30px;">
                      </div>
                      <div class="" v-for="(participant) in chat.participants"  :key="participant.id">
                        <div class="friend-name"  >
                          <strong>{{participant.name}}</strong>
                        </div>
                      </div>
                      <div class="last-message text-muted">{{ (chat.messages.length >0 )? (chat.messages[chat.messages.length-1].author + ": " +
                          (isText(chat.messages[chat.messages.length-1])?chat.messages[chat.messages.length-1].message: (isVoice(chat.messages[chat.messages.length-1])?"Voice Message":"Photo Message"))): ""}}</div>
                      <small class="time text-muted">{{ (chat.messages.length >0 )? getTimeMask(chat.messages[chat.messages.length-1]): ""}}</small>
                      <small v-if="chat.unreadMessageCount>0" class="chat-alert custom-label rounded-pill">{{(chat.unreadMessageCount>0)?chat.unreadMessageCount>0 : ""}}</small>
                      <small v-else class="chat-alert"><i class="fas fa-check-double" style="color: #93918f"></i></small>
                    </a>
                    </div>
                  </li>
                  <li class="bounceInDown" v-else>
                    <a href="#" @click="setActiveChat(index)" class="clearfix">
                      <div class=""  style="margin-left: 25px; ">
                        <img src="https://back.selfin.my-rent.net/Images/noname.jpg" alt="" class="avatar-group" style="margin-left: -30px;">
                      </div>
                      <div class="" >
                        <div class="friend-name"  >
                          <strong>No participants</strong>
                        </div>
                      </div>
                      <div class="last-message text-muted">{{ (chat.messages.length >0 )? (chat.messages[chat.messages.length-1].author + ": " +
                          (isText(chat.messages[chat.messages.length-1])?chat.messages[chat.messages.length-1].message: (isVoice(chat.messages[chat.messages.length-1])?"Voice Message":"Photo Message"))): ""}}</div>
                      <small class="time text-muted">{{ (chat.messages.length >0 )? getTimeMask(chat.messages[chat.messages.length-1]): ""}}</small>
                      <small v-if="chat.unreadMessageCount>0" class="chat-alert custom-label rounded-pill">{{(chat.unreadMessageCount>0)?chat.unreadMessageCount>0 : ""}}</small>
                      <small v-else class="chat-alert"><i class="fas fa-check-double" style="color: #93918f"></i></small>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="chats" v-else>
                  Empty list
              </div>
          </div>
        </div>

          <!--=========================================================-->
      <!-- selected chat -->

            <div class="col-md-8">
              <div class="card-custom">
                <div class="row heading">
                  <div class="col-sm-6 col-xs-6">
                    <div v-if="activeChat && activeChat.messages.length>0">
                     <span v-for="(participant, index) in activeChat.participants"  :key="participant.id" :index="index" >
                        <img :src="participant.avatarImageLink" :title="participant.name" width="40" height="40"  alt="" class="avatar-group">
                     </span>
                    </div>
                  </div>
                  <div class="col-sm-2 col-xs-2"></div>
                  <div class="col-sm-1 col-xs-1 heading-compose">
                    <a @click="openModalContact" id="add-user" title="Add user to chat"><i class="fas fa-user-plus fa-lg" aria-hidden="true"></i></a>
                  </div>
                  <teleport to="#modal-area">
<!--                    <form @submit.prevent="sendInvite">-->
                    <div v-if="modalOpenedContact"  class="modal-area-chats">
                      <span @click="closeModalContact" class="close-modal-chats">&times;</span>
                      <div class="modal-header" style="min-width:600px"><h3>Contacts:</h3></div>
                      <div class="modal-body">
                        <div class="row text-center">
                            <div class="form-group field-ticketform-assigned_to col-md-12">
                              <label class="control-label" data-custom-for="ticketform-team_id"></label>
                              <Multiselect
                                  v-model='userList'
                                  mode='tags'
                                  placeholder='Choose contact(s)...'
                                  trackBy='name'
                                  valueProp='id'
                                  label='name'
                                  :searchable='true'
                                  :options='workers'
                              >
                                <template v-slot:tag="{ option, remove, disabled }">
                                  <div class="multiselect-tag is-user">
                                    <img class="user-avatar" :src="option.avatarLink">
                                    {{ option.name }}
                                    <i
                                        v-if="!disabled"
                                        @click.prevent
                                        @mousedown.prevent.stop="remove(option)"
                                    />
                                  </div>
                                </template>
                                <template v-slot:option="{ option }">
                                  <img class="user-avatar" :src="option.avatarLink"> {{ option.name }}
                                </template>
                              </Multiselect>
                            </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <span @click="sendInvite" class="btn btn-warning" style="cursor:pointer"><i class="fas fa-plus"></i>   Add</span>
                      </div>
                    </div>
                  </teleport>
                  <div class="col-sm-1 col-xs-1 heading-compose">
                    <a  title="Media"><i class="fas fa-photo-video fa-lg" aria-hidden="true"></i></a>
                  </div>
                  <div class="col-sm-1 col-xs-1 heading-dot">
                    <a title="Info"><i class="fas fa-info fa-lg" aria-hidden="true"></i></a>
                  </div>
                  <div class="col-sm-1 col-xs-1 heading-dot">
                    <a title="Action"><i class="fas fa-ellipsis-h fa-lg" aria-hidden="true"></i></a>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1" style="margin: 10px 20px">
                    <input type="text" class="form-control" placeholder="Search..." v-model="searchMessage">
                  </div>
                </div>

                <div class="chat-message" v-if="activeChat && filteredMessages.length>0" ref="messageList">
                  <div class="chat clearfix" v-for="(message, index) in filteredMessages"  :key="message.id" :index="index">
                    <div :class="'chat-message-container '+(isNotMine(message)? ' left':' right') + ' clearfix'">
                      <div class="clearfix" v-if="isText(message)">
                          <div :class="isNotMine(message)?'float-start':'float-end'">
                            <img :src="message.avatarImageLink" class="avatar-img" :title="message.author" alt="User Avatar" width="40" height="40">
                          </div>
                          <div class="chat-body">
                              <div class="header">
                                <strong class="primary-font">{{ message.author }}</strong>
                                <small class="pull-right text-muted"><i class="far fa-clock"></i> {{ getTimeMask(message)}}</small>
                              </div>
                              <div class="chat-text-message text-left">
                                {{ message.message }}
                                <small class="pull-right" style="margin-top: 0px">
                                  <div class="dropdown reply-send">
                              <a class="dropdown-toggle hidden-arrow" href="#" role="button" id="dropdownMenuLink7" data-bs-toggle="dropdown" aria-expanded="false" style="font-size:15px; cursor: pointer;"  title="Actions">
                                <i class="fas fa-ellipsis-v"></i></a>
                              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink7">
                                <li><a class="dropdown-item" href="#" @click="editMsg(message.id)" >Edit</a></li>
                                <li><a class="dropdown-item" href="#" @click="forwardMsg(message.id)" >Forward</a></li>
                                <li v-if="!isNotMine(message)"><hr class="dropdown-divider"></li>
                                <li v-if="!isNotMine(message)"><a class="dropdown-item" href="#" style="color: red" @click="deleteMsg(message)" >Delete for all</a></li>
                              </ul>
                            </div>
                                </small>
                              </div>
                          </div>
                      </div>
                      <div class="clearfix" v-if="isPicture(message)">
                        <div :class="isNotMine(message)?'float-start':'float-end'">
                          <img :src="message.avatarImageLink" :title="message.author" class="avatar-img" alt="User Avatar" width="40" height="40">
                        </div>
                        <div class="chat-body">
                          <div class="header">
                            <strong class="primary-font">{{ message.author }}</strong>
                            <small class="pull-right text-muted"><i class="far fa-clock"></i> {{ getTimeMask(message)}}</small>
                          </div>
                          <div class="chat-text-message">
                            <div class="card p-2" v-for="(picture, index ) in message.photos" :key="index" style="width:9rem; margin: 2px; border: 1px solid #f1f5fc">
                              <a @click.prevent="openFullSize(picture.fullSizeLink)">
                                <img :src="picture.iconLink" class="card-img-top" alt="...">
                              </a>
                            </div>
                            <small class="pull-right text-muted" style="margin-top: -20px">
                              <div class="dropdown reply-send">
                                <a class="dropdown-toggle hidden-arrow" href="#" role="button" id="dropdownMenuLink8" data-bs-toggle="dropdown" aria-expanded="false" style="font-size:15px; cursor: pointer;"  title="Actions">
                                  <i class="fas fa-ellipsis-v"></i>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink8">
                                  <li><a class="dropdown-item" href="#" @click="forwardMsg(message.id)" >Forward</a></li>
                                  <li v-if="!isNotMine(message)"><hr class="dropdown-divider"></li>
                                  <li v-if="!isNotMine(message)"><a class="dropdown-item" href="#" style="color: red" @click="deleteMsg(message)" >Delete for all</a></li>
                                </ul>
                              </div>
                            </small>
                          </div>
                        </div>


                      </div>
                      <div class="clearfix" v-if="isVoice(message)">
                        <div :class="isNotMine(message)?'float-start':'float-end'">
                          <img :src="message.avatarImageLink" :title="message.author" class="avatar-img" alt="User Avatar" width="40" height="40">
                        </div>
                        <div class="chat-body">
                          <div class="header">
                            <strong class="primary-font">{{ message.author }}</strong>
                            <small class="pull-right text-muted"><i class="far fa-clock"></i> {{ getTimeMask(message)}}</small>
                          </div>
                          <div class="chat-text-message">
                            <div class="card p-2"  v-for="(record, index ) in message.photos" :key="index" style="width:8rem; margin: 2px; border: 1px solid #f1f5fc">
                              <a @click.prevent="playRecord(record.iconLink)" title = 'Start/Stop'>
                                Voice Message <img :src="startStopIcon" width="20" height="20">
                              </a>
                            </div>
                            <small class="pull-right text-muted" style="margin-top: -20px">
                              <div class="dropdown reply-send">
                              <a class="dropdown-toggle hidden-arrow" href="#" role="button" id="dropdownMenuLink6" data-bs-toggle="dropdown" aria-expanded="false" style="font-size:15px; cursor: pointer;"  title="Actions">
                                <i class="fas fa-ellipsis-v"></i>
                              </a>
                              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink6">
                                <li><a class="dropdown-item" href="#" @click="forwardMsg(message.id)" >Forward</a></li>
                                <li v-if="!isNotMine(message)"><hr class="dropdown-divider"></li>
                                <li v-if="!isNotMine(message)"><a class="dropdown-item" href="#" style="color: red" @click="deleteMsg(message)" >Delete for all</a></li>
                              </ul>
                              </div>
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="clearfix" v-if="isChat(message)">
                        <div class="chat-body">
                          <div class="header">
                            <strong class="primary-font">{{ message.author }}</strong>
                            <small class="pull-right text-muted"><i class="far fa-clock"></i> {{ getTimeMask(message)}}</small>
                          </div>
                          <div class="chat-text-message text-left">
                            {{ message.message }}
                            <small class="pull-right" style="margin-top: 0px">
                              <div class="dropdown reply-send">
                                <a class="dropdown-toggle hidden-arrow" href="#" role="button" id="dropdownMenuLink7" data-bs-toggle="dropdown" aria-expanded="false" style="font-size:15px; cursor: pointer;"  title="Actions">
                                  <i class="fas fa-ellipsis-v"></i></a>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink7">
                                  <li v-if="isChatAccept(message) && !isNotMine(message)"><a class="dropdown-item" href="#" @click="acceptInvite(message.id)" >Accept Invitation</a></li>
                                  <li v-if="isChatAccept(message) && !isNotMine(message)"><a class="dropdown-item" href="#" @click="declineInvite(message.id)" >Decline Invitation</a></li>
                                </ul>
                              </div>
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="chat-message" v-else>
                  <ul class="chat">
                    <li class="left clearfix">
                      <div class="chat-body clearfix">
                        <p>
                           There is no messages yet
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="row reply">
                  <div class="col-sm-1 col-xs-1 reply-attach">
                    <label class="custom-file-input" for="files" title="Attach">
                      <a class="reply-attach"><i class="fas fa-plus fa-2x" aria-hidden="true"></i></a>
                    </label>
                    <input id="files" type="file" multiple="multiple" name="PhotosForm[files]"  ref="files" accept="image/*" style="visibility: hidden;" v-on:change="handleFileUploads">
                  </div>
                  <div class="col-sm-9 col-xs-9 reply-main">
                    <textarea class="form-control" v-on:keyup="validateMessage" style="line-height: 2" rows="1" id="comment" placeholder="Type your message..." v-model="newMessage"></textarea>
                  </div>

                  <div v-if="isTyping" class="col-sm-2 col-xs-2 reply-send">
                    <a @click.prevent="sendMessage" class="reply-send float-end" title="Send"><i class="fas fa-paper-plane fa-2x" aria-hidden="true"></i></a>
                  </div>
                  <div v-else class="col-sm-2 col-xs-2 reply-recording">
                    <label class="float-end" for="voice-message" title="Recording voice message" >
                                          <span @click.prevent="startRecording" :class="microphone">
                                            <i class="fas fa-microphone fa-2x" aria-hidden="true"></i>
                                          </span>
                                          <span :class="recording" style="border: 1px solid #f1f5fc; border-radius: 10px; margin-top: 5px">
                                            <span @click.prevent="cancelRecording">
                                              <i class="far fa-times-circle" aria-hidden="true" style="margin-left: 5px; margin-right: 5px; color: red" title="Cancel"></i>
                                            </span>
                                            <span><i class="fas fa-circle fa-xs blinking"></i></span>
                                             {{recordingTime}}
                                            <span @click.prevent="stopRecordingAudio"><i class="far fa-check-circle" aria-hidden="true" title="Send"
                                              style="margin-left:5px;margin-right:5px; color: green"></i>
                                            </span>
                                          </span>
                    </label>
                    <input id="voice-message" type="file" multiple="multiple" name="SoundForm[files]"  ref="voiceRecord" accept="*/*" style="visibility: hidden;" v-on:change="handleRecording">
                  </div>
                </div>
              </div>
            </div>
        </div>
        </div>
      </div>
<!--    </div>-->
</template>

<script>
import {useStore} from "@/store";
import {computed, nextTick, onMounted, reactive, ref, toRefs} from "vue";
import {ActionTypes} from "@/store/actions";
import {MutationType} from "@/store/mutations";
import {Worker} from "../models/worker";
import {Message} from "../models/chat/message";
import chatService from "@/services/chatService";
//import VPagination from "vue3-pagination";
import flatPickr from "vue-flatpickr-component";
import Multiselect from "@vueform/multiselect";

export default {
name: "ChatList",
  setup(props) {
    const store = useStore();
    const messageList = ref(null);
    const modalOpenedContact = ref(false);
    const modalOpenedChat = ref(false);
    const workers = computed(() => store.getters.resources);
    const user = computed(() => store.getters.getUser);
    const filterForm = reactive({
      searchChat:"",
      searchMessage:"",
      newMessage:"",
      userList:[],
    })
    const filteredMessages = computed( () => store.getters.filteredActiveChatMessages(filterForm.searchMessage))
    const chats = computed(() => store.getters.filteredChats(filterForm.searchChat));
    const activeChat = computed(() => store.getters.getActiveChat);
    const scrollToTheEnd = () => {

      if (messageList.value !== null && messageList.value !== undefined)
        messageList.value.scrollTop = messageList.value.scrollHeight;
    }
    const setActiveChat = (index) => {
      store.commit(MutationType.setActiveChatId, chats.value[index].id);
      store.commit(MutationType.setActiveChat,chats.value[index]);
      nextTick(() => {scrollToTheEnd()})
    };
    let recorder = null;
    const items = [];
    let audioUrl = "";
    let audio = null;
    const microphone = ref("on-line")
    const recording = ref("off-line")
    const isTyping = ref(false)
    const speaking = ref("on-line")
    const seconds = ref(0)
    const recordingTime = computed(()=>{
      const minutes = Math.floor(seconds.value/60);
      const rest = seconds.value - minutes*60;
      return ((minutes>9)?minutes:"0"+minutes)+":"+((rest>9)?rest:"0"+rest);
    })
    const incrementSeconds = ()=>{ seconds.value ++;}
    let counter = null;
    const cancelRecording = () => {
      recording.value = "off-line";
      microphone.value = "on-line";
      counter = null;
      recorder = null;
      seconds.value = 0 ;
    }

    const sendRecordToServer = (recorder, blob) => {
      debugger
      const filename = new Date().getUTCMilliseconds()+'_chat_'+activeChat.value.id+'.wav';
      const formData = new FormData();
      formData.append("PhotosForm[files]", blob, filename);
      formData.append("MessageForm[chat_id]",activeChat.value.id)
      formData.append("MessageForm[message]","")
      formData.append("MessageForm[user_id]",user.value.id)
      formData.append("MessageForm[ticket_id]",activeChat.value.ticketId)
      formData.append("MessageForm[booking_id]",activeChat.value.bookingId)
      chatService.sendAudio(formData).then((response)=> {
        if (response.data.success) {
          store.commit(MutationType.getMessage,response.data)
        }
      })
      nextTick(() => {scrollToTheEnd()})
    }

    const startRecordingAudio = () => {
      const device = navigator.mediaDevices.getUserMedia({ audio: true });
      device.then((stream) => {
        // use this!
        recorder = new MediaRecorder(stream);
        recorder.ondataavailable = (e) => {
          items.push(e.data)
          //const blob = new Blob(items, { type: "audio/*" });
        };
        recorder.onstop = (e) => {
          debugger
          items.push(e.data)
          const blob = new Blob(items, { type: "audio/*" });
          audioUrl = URL.createObjectURL(blob);
          sendRecordToServer(recorder, blob);
          cancelRecording()
        };
        recorder.start();
      });
    }

    const stopRecordingAudio = () => {
      recorder.stop()
      cancelRecording()
    }
    const startRecording = (event) => {
      microphone.value = "off-line";
      recording.value = "on-line";
      if (counter===null)
        counter = setInterval(incrementSeconds, 1000);
      startRecordingAudio()
    }
    const isChatAccept = (message) => {
      debugger
      return message.serviceType === 'accept' &&  message.toWhome === user.value.id;
    }
    const isChat = (message) => {
      debugger
      return message.serviceType === 'chat' || message.serviceType === 'accept';
    }
    const isNotMine = (message) => {
      scrollToTheEnd()
      return message.userId !== user.value.id
    }
    const isPicture = (message) => {
      scrollToTheEnd()
      return message.type === "image"
    }
    const isText = (message) => {
      scrollToTheEnd()
      return message.serviceType === "message"
    }
    const isVoice = (message) => {
      scrollToTheEnd()
      return message.type === "voice"
    }
    const isVideo = (message) => {
      scrollToTheEnd()
      return message.type === "video"
    }
    const closeModalContact = () =>{
      modalOpenedContact.value = false
    };
    const openModalContact = () =>{
      modalOpenedContact.value = true
    };
    const closeModalChat = () =>{
      modalOpenedChat.value = false
    };
    const openModalChat = () =>{
      modalOpenedChat.value = true
    };
    const handleFileUploads = (event) => {
      const fileList = event.target.files;
      if (!fileList.length) return;
      const formData = new FormData();
      Array.from(Array(fileList.length).keys())
          .map(key => {
            formData.append("PhotosForm[files]", fileList[key], fileList[key].name);
          });
      formData.append("MessageForm[chat_id]",activeChat.value.id)
      formData.append("MessageForm[message]","")
      formData.append("MessageForm[user_id]",user.value.id)
      formData.append("MessageForm[ticket_id]",activeChat.value.ticketId)
      formData.append("MessageForm[booking_id]",activeChat.value.bookingId)
      chatService.sendPicture(formData).then((response)=> {
         store.commit(MutationType.getMessage,response.data)
      })
          nextTick(() => {scrollToTheEnd()})
    }
    const editMsg = (id)=> {
      console.log('msg edit '+ id )
    }
    const forwardMsg = (id)=> {
      console.log('msg forward '+ id )
    }
    const deleteMsg = (message)=> {
      debugger
      chatService.delete(message.id).then((response)=>{
        debugger
        if(response.status===200) {
          store.commit(MutationType.deleteMessage, message)
        }
      })
      nextTick(() => {scrollToTheEnd()})
    }
    const isPlaying = ref(false)
    const startStopIcon = ref('https://www.freeiconspng.com/uploads/play-button-icon-png-0.png')

    const getTimeMask = (message) => {
      const currentTime = (new Date()).getTime()
         const difference = currentTime - message.created*1000
      if (difference > 2*24*3600*1000) {
        return message.createdDateFormatted
      }
      if (difference > 24*3600*1000) {
        return "Yesterday, "+ message.timeMessageString.substr(0, 5)
      }
      if (difference > 1*3600*1000) {
        return message.timeMessageString.substr(0, 5)
      }
      if (difference < 3600*1000 && difference > 60*1000) {
        return (difference/1000/60).toFixed(0) + " minutes ago"
      }
      else {
        return "Just now"
      }
    };
    const playRecord = (url) => {
      if  (isPlaying.value === false) {
        isPlaying.value = true
        startStopIcon.value = 'https://cdn4.iconfinder.com/data/icons/multimedia-35/52/stop-button-512.png'
        audio = new Audio(url)
        audio.play()
      }
      else {
        if (audio!== null){
          audio.pause()
          audio = null
        }
        isPlaying.value = false
        startStopIcon.value = 'https://www.freeiconspng.com/uploads/play-button-icon-png-0.png'
      }
    }
    const openFullSize = (url) => {
      window.open(url, '_blank');
    }

    const sendMessage = () => {
      chatService.sendMessage({
        chat_id: activeChat.value.id,
        message: filterForm.newMessage,
        user_id: user.value.id,
        ticket_id: activeChat.value.ticketId,
        booking_id: activeChat.value.bookingId,
        photos: [],
      }).then((response) => {
        if(response.status===201) {
          console.log(response.data)
          store.commit(MutationType.getMessage, response.data)
          store.commit(MutationType.pushMessageToActiveChat, response.data)

        }
        else {
          console.log(response)
        }
        filterForm.newMessage=""
      });
      nextTick(() => {scrollToTheEnd()})
    }
    const validateMessage = (e) => {
      if (e.keyCode === 13) {
        isTyping.value = false
        speaking.value="on-line"
        sendMessage()
      }
      if (filterForm.newMessage.length===0) {
        isTyping.value = false
        speaking.value="on-line"
      }
      else {
        isTyping.value = true
        speaking.value = "off-line"
      }
    };
    const sendInvite = (id) => {
      sendInvite
      chatService.sendInviteMessage({
        chat_id: activeChat.value.id,
        ids: [id],
      }).then((response) => {
        if(response.status===200) {
          console.log(response.data)
          store.commit(MutationType.sendMessage, response.data)
          //activeChat.value.messages.push(response.data)
        }
        else {
          console.log(response)
        }
        filterForm.newMessage=""
      });
    }
    const acceptInvite = (id) => {
      sendInvite
      chatService.acceptInviteMessage({
        chat_id: activeChat.value.id,
        messageId: id,
      }).then((response) => {
        if(response.status===200) {
          console.log(response.data)
          store.commit(MutationType.sendMessage, response.data)
          //activeChat.value.messages.push(response.data)
        }
        else {
          console.log(response)
        }
        filterForm.newMessage=""
      });
    }
    const declineInvite = (id) => {
      sendInvite
      chatService.declineInviteMessage({
        chat_id: activeChat.value.id,
        messageId: id,
      }).then((response) => {
        if(response.status===200) {
          console.log(response.data)
          store.commit(MutationType.sendMessage, response.data)
          //activeChat.value.messages.push(response.data)
        }
        else {
          console.log(response)
        }
        filterForm.newMessage=""
      });
    }
    const leaveChat = (id) => {
      sendInvite
      chatService.leaveChat({
        chat_id: activeChat.value.id,
        ids: [id],
      }).then((response) => {
        if(response.status===200) {
          console.log(response.data)
          store.commit(MutationType.sendMessage, response.data)
          //activeChat.value.messages.push(response.data)
        }
        else {
          console.log(response)
        }
        filterForm.newMessage=""
      });
    }
    const searchChat = () => {
      console.log(filterForm.searchChat)
    }
    const searchMessage = (index) => {
     // activeChat.value.messages = activeChat.value.messages.filter((message) => { (message.indexOf(filterForm.searchMessage)>0) })
    }
    onMounted(async () => {
      await store.dispatch(ActionTypes.getUser);
      await store.dispatch(ActionTypes.fetchChats, Number(props.id))
      let idx = 0;
      if (chats.value.length>0) {
        if(props.chatId !== null && props.chatId !== undefined  && props.chatId !== "all")
          idx = Math.max(0, chats.value.findIndex(chat=>Number(chat.id) === Number(props.chatId)))
        store.commit(MutationType.setActiveChatId, idx);
        store.commit(MutationType.setActiveChat, chats.value[idx]);
      }
      nextTick(()=>{scrollToTheEnd()})
    });
    return { user, store, workers,  getTimeMask, setActiveChat, chats, activeChat, sendMessage, searchChat, searchMessage, validateMessage, messageList, scrollToTheEnd,
      modalOpenedContact, modalOpenedChat, openModalChat, closeModalChat, openModalContact, sendInvite, closeModalContact, handleFileUploads, speaking, isTyping,
      declineInvite, acceptInvite, leaveChat, isChat, isChatAccept,
      isPicture, isText, isVoice, isVideo, openFullSize, playRecord, isPlaying, startStopIcon, isNotMine , filteredMessages, startRecordingAudio, stopRecordingAudio,
       recording, microphone, startRecording, cancelRecording,  recordingTime, deleteMsg, editMsg, forwardMsg, ...toRefs(filterForm), }
       },

  components: { Multiselect },
  data () {
    return {
      name:"",
      descryption:"",
    }

  },
  props: {
    id: {
      type: Number,
      required: true
    },
    chatId: {
      type: String,
      required: false
    }
  },
  computed: {
    getAvatar() {
      if (this.user)
        return this.user.avatar_link;
      if (localStorage.getItem("AVATAR_LINK"))
        return localStorage.getItem("AVATAR_LINK");
      else return "https://extranet.ronabooking.com/Images/no_user.jpg";
    },
  },
}
</script>

<style scoped>

.rounded-pill {
  border-radius: 50rem!important;
}
.custom-file-input::before {
  content: "";
  display: inline-block;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
}

body {
  padding-top: 0;
  font-size: 12px;
  color: #777;
  background: #f9f9f9;
  font-family: 'Open Sans',sans-serif;
  margin-top:20px;
}
.modal-area-chats {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color:  #ffffff;
  color: #93918f;
  border-radius: 4px;
  border: 1px solid #eee;
  z-index: 10000;
  -webkit-box-shadow: inset 0px 0px 1px -2px rgba(0, 0, 0,.20), 0 2px 2px 0 rgba(0, 0, 0,.14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  box-shadow: inset 0px 0px 1px -2px rgba(0, 0, 0,.20), 0 2px 2px 0 rgba(0, 0, 0,.14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}
.close-modal-chats {
  float: right;
  font-size: 21px;
  font-weight: bold;
  position: absolute; right:10px;
  cursor: pointer;
}

.friend-list {
  list-style: none;
  margin-left: -40px;
}

.friend-list li {
  border-bottom: 1px solid #eee;
}

.friend-list li a img {
  float: left;
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.friend-list li a {
  position: relative;
  display: block;
  padding: 10px;
  transition: all .2s ease;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -ms-transition: all .2s ease;
  -o-transition: all .2s ease;
}

/*.friend-list li.active a {*/
/*  !*background-color: #ddecee;*!*/
/*  background-color: #eef5f6;*/
/*}*/

.friend-list li a:focus  {
  /*background-color: #ddecee;*/
  background-color: #eef5f6;
}
.avatar-img {
  border-radius: 50%;
  border: 2px solid #ffffff;
}
.avatar-group {
  /*padding-left: 10px;*/
  border-radius: 50%;
  /*margin-right: 8px;*/
  margin-left: -10px;
  border: 2px solid #ffffff;
  float: left;
}
.friend-list li a .friend-name,
.friend-list li a .friend-name:hover {
  color: #777;
  text-align: left;
}
.friend-list li a .last-message {
  width: 65%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
.friend-list li a .time {
  position: absolute;
  top: 10px;
  right: 8px;
}
small, .small {
  font-size: 85%;
}
.friend-list li a .chat-alert {
  position: absolute;
  right: 8px;
  top: 30px;
  font-size: 10px;
  padding: 3px 5px;
}
.chat-message {
  padding: 0px 20px 20px;
  height: 540px !important;
  overflow-y: scroll;
}
.chats {
  padding: 0px 10px 20px;
  height: 600px !important;
  overflow-y: scroll
}
.chat {
  list-style: none;
  margin: 0;
}
.chat-message {
  background: #f9f9f9;
}

/*.chat li img {*/
/*  width: 45px;*/
/*  height: 45px;*/
/*  border-radius: 50em;*/
/*  -moz-border-radius: 50em;*/
/*  -webkit-border-radius: 50em;*/
/*}*/

img {
  max-width: 100%;
}

.chat-body {
  padding-bottom: 20px;
}

.chat-message-container.left .chat-body {
  margin-left: 70px;
  background-color: #fff;
}

.chat-message-container .chat-body {
  position: relative;
  font-size: 11px;
  padding: 10px;
  border: 1px solid #f1f5fc;
  box-shadow: 0 1px 1px rgba(0,0,0,.05);
  -moz-box-shadow: 0 1px 1px rgba(0,0,0,.05);
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
}

.chat-message-container .chat-body .header {
  padding-bottom: 5px;
  text-align: left;
  border-bottom: 1px solid #f1f5fc;
}

.chat-message-container .chat-body p {
  margin: 0;
  text-align: left;
}

.chat-message-container.left .chat-body:before {
  position: absolute;
  top: 10px;
  left: -8px;
  display: inline-block;
  background: #fff;
  width: 16px;
  height: 16px;
  border-top: 1px solid #f1f5fc;
  border-left: 1px solid #f1f5fc;
  content: '';
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

.chat-message-container.right .chat-body:before {
  position: absolute;
  top: 10px;
  right: -8px;
  display: inline-block;
  background: #fff;
  width: 16px;
  height: 16px;
  border-top: 1px solid #f1f5fc;
  border-right: 1px solid #f1f5fc;
  content: '';
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

.chat-message-container {
  margin: 15px 0;
}
.chat-text-message {
  padding-top:2px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  text-align: left;
}

.chat-message-container.right .chat-body {
  margin-right: 70px;
  background-color: #fff;
}

.chat-box {
  padding: 15px;
  border-top: 1px solid #eee;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
}

.primary-font {
  color: #3c8dbc;
}

a:hover, a:active, a:focus, a {
  text-decoration: none;
  outline: 0;
}

.reply {
  height: 60px;
  width: 100%;
  /*background-color: #eef5f6;*/
  padding: 10px 5px 10px 5px !important;
  margin: 0 !important;
  border-top: 1px solid #f1f5fc;
  /*z-index: 1000;*/
}
.heading {
  height: 60px;
  width: 100%;
  background-color: #eef5f6;
  padding: 10px 10px 10px 10px !important;
  margin: 0 !important;
}

.reply-attach {
  padding: 5px !important;
}

.reply-attach a {
  text-align: center;
  padding: 5px 5px 5px 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-recording {
  padding: 5px !important;
}

.reply-recording span {
  text-align: left;
  padding: 1px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-send {
  padding: 5px !important;
}

.reply-send a {
  text-align: center;
  padding: 5px !important;
  color: #93918f;
  cursor: pointer;
}
.heading-dot a, .heading-compose a, .reply-send a, .reply-recording span, .reply-attach a {
  color: #93918f;
  cursor: pointer;
}
.reply-attach a {
  color: #93918f;
  cursor: pointer;
}
.reply-main {
  padding: 2px 5px !important;
}

.reply-main textarea {
  width: 100%;
  resize: none;
  overflow: hidden;
  padding: 5px !important;
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
  /*height: 100%;*/
  font-size: 16px;
}

.reply-main textarea:focus {
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
}
.reply-send a:hover{
  color: #f26c0d;
}
.reply-recording span:hover{
  color: #f26c0d;
}
.reply-attach a:hover{
  color: #f26c0d;
}
.heading-dot a:hover{
  color: #f26c0d;
}
.heading-compose a:hover {
  color: #f26c0d;
}
.recording {

}
.microphone {

}
.on-line {
  display: inline-block;
}
.off-line {
  display: none;
}
.blinking {
  color:  red;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.full-size {
  width: 400px;
  max-width: 100%;
}

</style>
